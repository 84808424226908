// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-husky-src-templates-404-404-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/404/404.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-404-404-tsx" */),
  "component---gatsby-theme-husky-src-templates-article-about-strepsils-page-index-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ArticleAboutStrepsilsPage/index.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-article-about-strepsils-page-index-tsx" */),
  "component---gatsby-theme-husky-src-templates-article-landing-page-article-landing-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ArticleLandingPage/ArticleLandingPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-article-landing-page-article-landing-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-article-page-article-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ArticlePage/ArticlePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-article-page-article-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ContactUsPage/ContactUsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-herbal-page-herbal-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/HerbalPage/HerbalPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-herbal-page-herbal-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-product-quiz-page-product-quiz-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ProductQuizPage/ProductQuizPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-product-quiz-page-product-quiz-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-products-list-page-products-list-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/ProductsListPage/ProductsListPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-products-list-page-products-list-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-search-result-page-search-result-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/SearchResultPage/SearchResultPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-search-result-page-search-result-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-simple-page-simple-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/SimplePage/SimplePage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-simple-page-simple-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-site-map-page-site-map-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/SiteMapPage/SiteMapPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-site-map-page-site-map-page-tsx" */),
  "component---gatsby-theme-husky-src-templates-where-to-buy-page-where-to-buy-page-tsx": () => import("./../../../../gatsby-theme-husky/src/templates/WhereToBuyPage/WhereToBuyPage.tsx" /* webpackChunkName: "component---gatsby-theme-husky-src-templates-where-to-buy-page-where-to-buy-page-tsx" */)
}

